import { acceptHMRUpdate, defineStore } from 'pinia'
import apiService from '@/services/api.service'
import { useAuthUserStore } from './auth-user'
import date from 'date-and-time'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useProjectsStore } from './projects'
import { useClientsStore } from './clients'
import { useServicesStore } from './services'
import notificationService from '@/services/notification.service'
import { useApiStore } from './api'
import { captureException, captureMessage } from '@sentry/vue'
import datetime from '@/lib/datetime'
import { $t } from '@/config/i18n'
import { useNotificationsStore } from './notifications'
import idleService from '@/services/idle.service'
import { toRaw } from 'vue'

/**
 * @typedef {{
 *  id: string,
 *  ext_tool_id: ?string,
 *  description: ?string,
 *  user_id: ?string,
 *  client_id: ?string,
 *  project_id: ?string,
 *  service_id: ?string,
 *  started_at: ?string,
 *  ended_at: ?string,
 *  billable: boolean,
 *  paid: ?string,
 *  created_at: ?string,
 *  updated_at: ?string,
 *  push_state: ?number,
 *  push_errors: any[],
 *  tl: {
 *    isDirty: boolean
 *    origin: Object
 *  }
 * }} TimeEntry
 */

export const useTimeEntryStore = defineStore('timeEntry', {
  /**
   *
   * @returns {{
   * timeEntries: Array<TimeEntry>,
   * last_fetch: Array,
   * activeTimeEntry: ?string,
   * lastActiveTimeEntryChangeOrFetch: ?number,
   * lastNotificationToStartTracking: ?number,
   * changedIds: Array<string>,
   * newIds: Array<string>,
   * createdIds: Array<string>,
   * deletedIds: Array<string>,
   * requiredFields: Array<string>,
   * interruptedId: ?string,
   * isLoadingActive: boolean,
   * selectedTimeInterval: { start: string, end: string },
   * actualTimeInterval: { start: string, end: string },
   * conflicts:  Array<{a: string, b: ?string, type: number}>
   * }}
   */
  state: () => {
    return {
      timeEntries: [],
      tempNewEntry: null,
      activeTimeEntry: null,
      lastActiveTimeEntryChangeOrFetch: null,
      lastNotificationToStartTracking: null,
      last_fetch: [],
      changedIds: [],
      newIds: [],
      createdIds: [],
      deletedIds: [],
      requiredFields: [],
      interruptedId: null,
      isLoadingActive: false,
      selectedTimeInterval: { start: null, end: null },
      actualTimeInterval: { start: null, end: null },
      conflicts: []
    }
  },
  persist: true,
  getters: {
    getInterval: (state) => {
      /**
       * @returns {Array<TimeEntry>}
       */
      return (start, end) => {
        return datetime.getInterval(state.timeEntries, start, end)
      }
    },
    findId: (state) => {
      /**
       * @param {?string} id
       * @returns {?TimeEntry}
       */
      return (id) => {
        if (id == undefined || id == null) {
          return null
        }
        if (id == 'tempNewEntry') {
          return state.tempNewEntry
        }
        return state.timeEntries.find((item) => item.id == id) ?? null
      }
    },
    getId: (state) => {
      /**
       * @param {?string} id
       * @returns {?TimeEntry}
       */
      return (id) => {
        if (id == undefined || id == null) {
          return null
        }
        if (id == 'tempNewEntry') {
          return state.tempNewEntry
        }
        let entry = null
        if ((entry = state.timeEntries.find((item) => item.id == id))) {
          return entry
        }
        try {
          if (!timelinkStoresService.isTempId(id)) {
            state.fetchId(id)
          }
        } catch (error) {
          console.log(error)
        }
        return state.timeEntries.find((item) => item.id == id) ?? null
      }
    },
    /**
     *
     *
     * @returns {?TimeEntry}
     */
    getActiveTimeEntry: (state) => {
      if (state.activeTimeEntry && state.getId(state.activeTimeEntry)?.ended_at) {
        state.activeTimeEntry = null
        state.lastActiveTimeEntryChangeOrFetch = Date.now()
        return state.getId(null)
      }
      return state.getId(state.activeTimeEntry)
    },
    getTimeEntries: (state) => {
      return state.timeEntries
    },
    getActualTimeInterval: (state) => {
      if (state.actualTimeInterval?.start == null) {
        let now = new Date()
        let startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        startDate = date.addDays(startDate, 0 - startDate.getDay())
        // eslint-disable-next-line no-unused-vars
        let { start, end, previous_start, previous_end, follow_start, follow_end } =
          datetime.calcInterval(startDate, 'week')
        state.actualTimeInterval = { start: previous_start, end: follow_end }
        if (state.selectedTimeInterval.start == null) {
          state.selectedTimeInterval = { ...state.actualTimeInterval }
        }
      }
      return state.actualTimeInterval
    },
    getSelectedTimeInterval: (state) => {
      if (state.selectedTimeInterval.start == null) {
        state.getActualTimeInterval
      }
      return state.selectedTimeInterval
    }
  },
  actions: {
    /*
     * Fetch functions
     */

    async initFetch() {
      let { start, end } = this.getActualTimeInterval
      this.resetIds()
      // clear null objects if found
      this.timeEntries = this.timeEntries.filter((item) => item !== undefined && item !== null)
      this.fetchUpdates()
      this.isLoadingActive = false
      this.loadActive()
      this.fetchRequiredFields()
      await this.fetchInterval(start, end)
      if (this.timeEntries.length < 6) {
        if (this.timeEntries.filter((item) => item.started_at && item.ended_at).length < 4) {
          this.fetch({
            limit: 6,
            orders: [
              {
                column: 'started_at',
                direction: 'desc'
              }
            ]
          })
        }
      }
      this.conflicts = []
      timelinkStoresService.setOrRenewTimeout(
        this.$id,
        'initConflicts',
        () => {
          this.fetchConflicts()
        },
        750
      )
      this.notifications()
    },

    async fetch(params) {
      try {
        let response = await apiService.fetch(
          import.meta.env.VITE_API_URL + '/api/v1/timeEntries',
          params
        )
        response?.data?.forEach((item) => {
          this.addOrUpdate(item)
        })
        this.renewConflicts()
      } catch (error) {
        console.error(error)
      }
    },

    async fetchInterval(start, end, callback) {
      return await new Promise((resolve, reject) => {
        apiService.fetchAll(
          import.meta.env.VITE_API_URL + '/api/v1/timeEntries',
          {
            start: start,
            end: end,
            withRelations: true
          },
          (data) => {
            data.data.forEach((item) => {
              this.addOrUpdate(item, false, 'fetchInterval')
            })
            this.internalUpdateActiveTimeEntry()

            if (typeof callback == 'function') {
              callback()
            }
            this.renewConflicts()
          },
          (error) => {
            reject(error)
          },
          () => {
            resolve(true)
          }
        )
      })
    },
    fetchRequiredFields() {
      apiService.fetchAll(
        import.meta.env.VITE_API_URL + '/api/v1/timeEntries/fieldsRequired',
        {},
        (data) => {
          if (Array.isArray(data.data)) {
            this.requiredFields = data.data
          }
        },
        () => { }
      )
    },

    validate(timeEntry) {
      if (!timeEntry) {
        return [...this.requiredFields]
      }
      let isActiveTimeEntry = timeEntry.id == this.activeTimeEntry
      let notSatisfied = this.requiredFields
        .filter((item) => item != 'ended_at' || (item == 'ended_at' && !isActiveTimeEntry))
        .filter((item) => timeEntry[item] == undefined || timeEntry[item] == null)

      return notSatisfied.length == 0 ? true : notSatisfied
    },
    /**
     *
     * @param {string} id
     * @returns
     */
    async fetchId(id) {
      if (timelinkStoresService.isTempId(id) || id == 'tempNewEntry') {
        return
      }
      this.resetIds()

      if (typeof id == 'object') {
        captureMessage('Function got called with an object instead of a string', [typeof id, id])
        if (Object.hasOwn(id, 'id')) {
          id = id.id
        } else {
          return
        }
      }
      try {
        let response = await apiService.fetchId(
          import.meta.env.VITE_API_URL + '/api/v1/timeEntries',
          id,
          { withRelations: true }
        )

        let data = response.data
        this.addOrUpdate(data, true, 'fetchId')
        this.internalUpdateActiveTimeEntry()
      } catch (error) {
        if (error?.response?.status == 404) {
          console.trace()
          this.removeId(id)
          this.deletedIds.push(id)
          this.removeFromConflicts(id)
        } else {
          captureException(error)
        }
      }
    },

    async fetchIds(ids, returnFoundIds = false) {
      const limit = 200
      if (!Array.isArray(ids)) {
        this.fetchId(ids)
      }
      let chunks = []
      let pages = Math.ceil(ids.length / limit)
      for (let i = 0; i < pages; i++) {
        chunks.push(ids.slice(i * limit, (i + 1) * limit))
      }

      let foundIds = []

      try {
        for (let i = 0; i < chunks.length; i++) {
          this.resetIds()

          let data = await apiService.post(
            import.meta.env.VITE_API_URL + '/api/v1/timeEntries/search',
            {
              ids: chunks[i],
              withRelations: true,
              limit: limit
            }
          )
          data.data.forEach((item) => {
            this.addOrUpdate(item)
            if (returnFoundIds) {
              foundIds.push(item.id)
            }
          })
        }
      } catch (error) {
        captureException(error)
        throw error
      }
      return foundIds
    },

    async fetchUpdates() {
      let ids = []
      this.timeEntries
        .filter((item) => !timelinkStoresService.isTempId(item.id) && item.id != 'tempNewEntry')
        .forEach((item) => {
          ids.push(item.id)
        })
      if (ids.length == 0) {
        return
      }

      try {
        let foundIds = await this.fetchIds(ids, true)
        let notFound = ids.filter((item) => !foundIds.includes(item))
        this.timeEntries = this.timeEntries.filter((item) => !notFound.includes(item.id))
        this.renewConflicts()
      } catch (error) {
        captureException(error)
      }
    },

    async loadActive() {
      if (
        typeof this.activeTimeEntry != 'undefined' &&
        this.activeTimeEntry !== null &&
        typeof this.activeTimeEntry != 'number' &&
        typeof this.activeTimeEntry != 'string'
      ) {
        console.error(this.activeTimeEntry)
        let falseEntry = this.activeTimeEntry
        let typeActive = typeof this.activeTimeEntry
        this.activeTimeEntry = null
        try {
          throw new Error('Active time entry got not expected type. Type: ' + typeActive)
        } catch (err) {
          captureException(err, [falseEntry])
        }
      }
      if (this.activeTimeEntry && !timelinkStoresService.isTempId(this.activeTimeEntry)) {
        await this.fetchId(this.activeTimeEntry)
      }
      if (this.isLoadingActive) {
        timelinkStoresService.setOrRenewTimeout(
          this.$id,
          'loadActiveReset',
          () => {
            this.isLoadingActive = false
          },
          500
        )
        return
      }
      this.isLoadingActive = true
      try {
        let data = await apiService.fetch(
          import.meta.env.VITE_API_URL + '/api/v1/timeEntries/active',
          { limit: 50, withRelations: true }
        )
        data.data.forEach((item) => {
          this.addOrUpdate(item, false, 'loadActive')
        })
        this.isLoadingActive = false
        this.internalUpdateActiveTimeEntry()
      } catch (error) {
        captureException(error)
      }
      this.renewConflicts()
    },
    internalUpdateActiveTimeEntry() {
      let found_id =
        this.timeEntries.find(
          (item) => item.ended_at == null && !useApiStore().deleteHasId('timeEntries', item.id)
        )?.id ?? null
      if (found_id && this.activeTimeEntry == found_id) {
        this.automaticStop()
        return
      } else if (found_id != this.activeTimeEntry) {
        this.$patch({
          activeTimeEntry: found_id,
          lastActiveTimeEntryChangeOrFetch: Date.now()
        })
        if (found_id == null) {
          this.lastNotificationToStartTracking = null
        }
        this.notifications()
        this.automaticStop()
      }
    },
    addOrUpdate(entry, loadRelations = false) {
      let o_entry = null
      let client = null
      let project = null
      let service = null
      if (entry.client) {
        client = { ...entry.client }
        entry.client = undefined
        delete entry.client
      }
      if (entry.project) {
        project = { ...entry.project }
        entry.project = undefined
        delete entry.project
      }
      if (entry.service) {
        service = { ...entry.service }
        entry.service = undefined
        delete entry.service
      }
      if (entry.user) {
        entry.user = undefined
        delete entry.user
      }
      if (entry.data != undefined && entry.data != null) {
        let temp = { ...entry.data }
        entry = temp
      }
      if ((o_entry = this.timeEntries.find((item) => entry.id === item.id))) {
        let changed = false
        o_entry.tl.origin = entry
        o_entry.tl.last_fetch = Date.now()
        if (!o_entry.tl.isDirty) {
          if (entry.updated_at != o_entry.updated_at) {
            Object.entries(entry).forEach((item) => {
              if (o_entry[item[0]] !== item[1]) {
                o_entry[item[0]] = item[1]
                changed = true
              }
            })
          }
        }

        if (loadRelations && !client && !service && !project) {
          this.loadRelations(entry)
        }
        this.removeSearchFlags(entry)

        if (this.activeTimeEntry && this.activeTimeEntry == entry.id) {
          if (this.getId(this.activeTimeEntry)?.ended_at != null) {
            this.$patch({
              activeTimeEntry: null,
              lastActiveTimeEntryChangeOrFetch: Date.now()
            })
          } else {
            this.$patch({
              activeTimeEntry: o_entry.id,
              lastActiveTimeEntryChangeOrFetch: Date.now()
            })
          }
        }
        if (changed) {
          this.changedIds.push(entry.id)
        }
      } else {
        entry.tl = {
          origin: { ...entry },
          isDirty: false,
          last_fetch: Date.now(),
          isUpdating: false
        }
        this.timeEntries.push(entry)
        this.newIds.push(entry.id)
        if (loadRelations) {
          this.loadRelations(entry)
        }
      }
      if (client) {
        useClientsStore().addOrUpdate(client)
      }
      if (project) {
        useProjectsStore().addOrUpdate(project)
      }
      if (service) {
        useServicesStore().addOrUpdate(service)
      }
    },

    /*
     * Create functions
     */

    newEntry(started_at = null, ended_at = null, withoutEnd = false, withoutReference = false) {
      this.newIds = []
      let nowDate = new Date(Date.now())
      nowDate = new Date(
        nowDate.getFullYear(),
        nowDate.getMonth(),
        nowDate.getDate(),
        nowDate.getHours(),
        nowDate.getMinutes(),
        nowDate.getSeconds()
      )
      let entry = {
        id: 'tempNewEntry',
        billable: true,
        client_id: null,
        created_at: null,
        deleted_at: null,
        updated_at: null,
        description: null,
        started_at: started_at ?? nowDate.toISOString(),
        ended_at:
          ended_at ??
          (!withoutEnd
            ? started_at
              ? date.addMinutes(new Date(Date.parse(started_at)), 30).toISOString()
              : new Date(nowDate.valueOf() + 30 * 60 * 1000).toISOString()
            : null),
        ext_tool_id: null,
        last_push: null,
        paid: false,
        project_id: null,
        push_error: null,
        push_state: 0,
        service_id: null,
        tl: {
          isDirty: true,
          origin: null,
          last_fetch: null
        },
        user_id: null
      }
      if (withoutReference) {
        entry.id = timelinkStoresService.getTempId()
        return entry
      }
      return entry
    },
    async createOrUpdateEntry(id) {
      if (id === 'tempNewEntry') {
        id = timelinkStoresService.getTempId()
        this.tempNewEntry.id = id
        this.tempNewEntry.tl.isDirty = false
        this.timeEntries.push(this.tempNewEntry)
        this.tempNewEntry = null
        this.newIds.push(id)
      }
      if (timelinkStoresService.isTempId(id)) {
        await this.createEntry(id)
      } else {
        await this.update(id)
      }
      return this.getId(id)
    },
    async createEntry(id, isRetry = false) {
      const entry = this.getId(id)
      if (!entry) {
        if (isRetry) {
          useApiStore().removeIdFromCreate('timeEntries', id)
          captureMessage('Could not create entry! Store got no references to it.')
        }
        return
      }
      this.createdIds = []
      this.removeSearchFlags(entry)
      entry.tl.isUpdating = true

      try {
        let data = await apiService.createEntry(
          'timeEntries',
          import.meta.env.VITE_API_URL + '/api/v1/timeEntries',
          id,
          {
            billable: entry.billable,
            client_id: entry.client_id,
            description: entry.description,
            started_at: new Date(Date.parse(entry.started_at)).toUTCString(),
            ended_at: entry.ended_at ? new Date(Date.parse(entry.ended_at)).toUTCString() : null,
            paid: entry.paid,
            project_id: entry.project_id,
            service_id: entry.service_id,
            user_id: entry.user_id ?? useAuthUserStore().user.id
          },
          () => { },
          () => { },
          isRetry
        )
        if (!data) {
          return
        }
        let dataObj = data.data
        timelinkStoresService.removeError(entry)
        if (this.timeEntries.find((item) => item.id == dataObj.id)) {
          this.timeEntries = this.timeEntries.filter((item) => item.id != dataObj.id)
        }
        entry.tl.isUpdating = false
        entry.id = dataObj.id
        entry.tl.isDirty = false
        if (this.activeTimeEntry && this.activeTimeEntry == id) {
          this.$patch({
            activeTimeEntry: dataObj.id,
            lastActiveTimeEntryChangeOrFetch: Date.now()
          })
        }
        this.addOrUpdate(dataObj, false, 'createEntry')
        this.createdIds.push({
          old: id,
          new: dataObj.id
        })
        if (this.interruptedId && this.interruptedId == id) {
          this.interruptedId = dataObj.id
        }
        useAuthUserStore().updateLastUsed('clients', entry.client_id)
        useAuthUserStore().updateLastUsed('projects', entry.project_id)
        useAuthUserStore().updateLastUsed('services', entry.service_id)
        const sorti = async function () {
          useProjectsStore().sortEntries()
          useClientsStore().sortEntries()
          useServicesStore().sortEntries()
        }
        timelinkStoresService.setOrRenewTimeout(
          'timeEntryStore',
          'sorti',
          () => {
            sorti()
          },
          100
        )
        this.renewConflicts()
        // let entryC = this.getId(response.data.created[0])
      } catch (error) {
        if (error.code == 'ERR_NETWORK' || error.code == 'ERR_CONNECTION_REFUSED') {
          entry.tl.error = 'errors.noConnection'
          entry.tl.error_code = 1
        } else {
          console.error(error)
          entry.tl.error = 'Ups. Es ist ein Fehler bei der Übertragung aufgetreten.'
          entry.tl.error_code = 9999
        }
      }
    },

    /**
     *
     * @param {string|{client_id: ?string, project_id: ?string, service_id: ?string, description: ?string}} base
     * @param {boolean} withoutDescription
     * @param {boolean} overDeck
     * @returns
     */
    async createNewActiveTimeEntry(base = null, withoutDescription = false, overDeck = false) {
      await this.loadActive()
      if (this.activeTimeEntry) {
        if (!this.getId(this.activeTimeEntry)?.ended_at) {
          return this.getId(this.activeTimeEntry)
        } else {
          let id = this.activeTimeEntry
          await this.updateId(id)
        }
      }
      let entry = { ...this.newEntry(null, null, true, true) }
      entry.id = timelinkStoresService.getTempId()
      if (base) {
        let baseEntry = null
        if (typeof base == 'string') {
          baseEntry = this.getId(base)
          if (baseEntry) {
            entry.client_id = baseEntry.client_id
            entry.service_id = baseEntry.service_id
            entry.project_id = baseEntry.project_id
            entry.description = !withoutDescription ? (baseEntry?.description ?? '') : ''
          }
        } else if (typeof base == 'object') {
          baseEntry = { ...base }
          entry.client_id = baseEntry?.client_id
          entry.service_id = baseEntry?.service_id
          entry.project_id = baseEntry?.project_id
          entry.description = !withoutDescription ? (baseEntry?.description ?? '') : ''
        } else {
          throw Error('Only string or object is supported for the variable baseId.')
        }
      }
      let maxId = null
      let max = 0
      let diff = 60000
      this.timeEntries.forEach((item) => {
        if (
          item.ended_at &&
          Date.parse(item.ended_at) > max &&
          Date.parse(item.ended_at) < Date.parse(entry.started_at) &&
          Date.parse(item.ended_at) >= Date.parse(entry.started_at) - diff
        ) {
          maxId = item.id
          max = Date.parse(item.ended_at)
        }
      })
      if (max != 0 && maxId != null) {
        entry.started_at = new Date(max).toISOString()
      }
      this.timeEntries.push(entry)
      this.$patch({
        activeTimeEntry: entry.id,
        lastActiveTimeEntryChangeOrFetch: Date.now()
      })

      if (
        (useAuthUserStore().user?.settings?.notifications?.onTrackingStartAndStopOverPanel
          ?.active ??
          true) &&
        overDeck
      ) {
        useNotificationsStore().send({
          type: 'onTrackingStart',
          severity: { level: 0, type: 'info' },
          data: [],
          options: { store: false }
        })
      }

      this.newIds.push(entry.id)
      await this.createEntry(entry.id)
      return this.getId(entry.id)
    },

    delayUpdateEntry(entry, timeoutTimer = 1000) {
      timelinkStoresService.setOrRenewTimeout(
        this.$id,
        entry.id,
        () => {
          this.updateEntry(entry.id)
        },
        timeoutTimer
      )
    },

    delayUpdateId(id, timeoutTimer = 1000, noUpdate = false) {
      timelinkStoresService.setOrRenewTimeout(
        this.$id,
        id,
        () => {
          this.update(id, noUpdate)
        },
        timeoutTimer
      )
    },
    /*
     * Update functions
     */
    async update(ids, noUpdate = false) {
      if (!Array.isArray(ids)) {
        ids = [ids]
      }

      this.resetIds()
      for (const item of ids) {
        await this.updateId(item, false, noUpdate)
      }
    },
    async updateAsync(ids, noUpdate = false) {
      if (!Array.isArray(ids)) {
        ids = [ids]
      }
      this.resetIds()
      for (const item of ids) {
        await this.updateId(item, false, noUpdate)
      }
    },
    async updateId(id, isRetry = false, noUpdate = false) {
      if (timelinkStoresService.isTempId(id)) {
        return
      }
      let entry = this.timeEntries.find((item) => item.id == id)
      if (!entry) {
        if (isRetry) {
          useApiStore().removeIdFromUpdate('timeEntries', id)
          captureMessage('Retry without existing entry in store!')
        }
        return
      }
      timelinkStoresService.updateTl(entry)

      this.removeSearchFlags(entry)

      if (!entry.tl.isDirty) {
        return
      }
      entry.tl.isUpdating = true
      entry.tl.isDirty = false
      // TODO: create a better flow for it.
      try {
        let response = await apiService.updateEntry(
          'timeEntries',
          import.meta.env.VITE_API_URL + '/api/v1/timeEntries',
          id,
          {
            description: entry.description,
            started_at: entry.started_at,
            ended_at: entry.ended_at,
            client_id: entry.client_id,
            project_id: entry.project_id,
            service_id: entry.service_id,
            billable: entry.billable
          },
          () => { },
          () => { },
          isRetry
        )
        let data = response.data
        timelinkStoresService.removeError(entry)
        // this.changedIds.push(entry.id)
        entry.tl.isUpdating = false
        if (!noUpdate) {
          this.addOrUpdate(data, false, 'updateId')
          timelinkStoresService.updateTl(entry)
        } else {
          entry.tl.isDirty = true
          this.addOrUpdate(data, false, 'updateId')
          entry.updated_at = data.updated_at
          entry.tl.isDirt = false
        }
        timelinkStoresService.updateTl(this.getId(entry.id))
        this.internalUpdateActiveTimeEntry()
        useAuthUserStore().updateLastUsed('clients', entry.client_id)
        useAuthUserStore().updateLastUsed('projects', entry.project_id)
        useAuthUserStore().updateLastUsed('services', entry.service_id)
        const sorti = async function () {
          useProjectsStore().sortEntries()
          useClientsStore().sortEntries()
          useServicesStore().sortEntries()
        }
        timelinkStoresService.setOrRenewTimeout(
          'timeEntries',
          'sorti',
          () => {
            sorti()
          },
          200
        )
        this.changedIds.push(id)
        this.renewConflicts()
      } catch (error) {
        if (error.code == 'ERR_NETWORK' || error.code == 'ERR_CANCELED') {
          entry.tl.error = 'errors.NoUpdateCausedByNoConnection'
          entry.tl.error_code = 1
        } else {
          console.error(error)
          entry.tl.error = 'Es ist ein Fehler aufgetreten!'
          entry.tl.error_code = 9999
        }
      }
    },

    /**
     * This function will also reset the interruptedId. Assign it with toRaw before calling this function.
     * @param {*} ended_at 
     * @param {*} fetchBefore 
     * @param {boolean} overDeck 
     * @returns {null|string}
     */
    async stopActiveEntry(ended_at = null, fetchBefore = false, overDeck = false) {
      if (!this.activeTimeEntry) {
        return null
      }
      if (fetchBefore) {
        await this.fetchId(this.activeTimeEntry)
        if (this.activeTimeEntry.ended_at) {
          this.internalUpdateActiveTimeEntry()
          return false
        }
      }
      // let entry = this.getActiveEntry
      const id = this.activeTimeEntry
      const nowDate = new Date(Date.now())
      this.getId(this.activeTimeEntry).ended_at =
        ended_at != null
          ? ended_at
          : new Date(
            nowDate.getFullYear(),
            nowDate.getMonth(),
            nowDate.getDate(),
            nowDate.getHours(),
            nowDate.getMinutes(),
            nowDate.getSeconds()
          ).toISOString()
      const start = new Date(Date.parse(this.getId(this.activeTimeEntry)?.started_at))
      this.interruptedId = null
      if (
        date.addMinutes(start, 1) > new Date(Date.parse(this.getId(this.activeTimeEntry)?.ended_at))
      ) {
        await this.deleteId(this.activeTimeEntry)

        useNotificationsStore().send({
          type: "recordingBelowOneMinute",
          options: { store: false },
          until: Date.now() + 10 * 60 * 1000
        }
          // $t('notifications.timeEntries.recording_below_one_minute.title'),
          // $t('notifications.timeEntries.recording_below_one_minute.body')
        )

        return null
      }
      await this.update([id])
      if (
        (useAuthUserStore().user?.settings?.notifications?.onTrackingStartAndStopOverPanel
          ?.active ??
          true) &&
        overDeck
      ) {
        useNotificationsStore().send({
          type: 'onTrackingStopOverPanel',
          options: { store: false },
          data: { n: Math.round((Date.parse(nowDate) - Date.parse(start)) / 1000 / 60) }
        })
      }
      this.$patch({
        activeTimeEntry: null,
        lastActiveTimeEntryChangeOrFetch: Date.now()
      })
      return this.activeTimeEntry == null ? id : null
    },
    async stopAndCloneActiveTimeEntry(overDeck = false) {
      let oldId = await this.stopActiveEntry(null, false, overDeck)
      if (!oldId) {
        return
      }
      await this.createNewActiveTimeEntry(oldId)
    },

    async stopAndSaveAsInterruptedWithNewEntry(overDeck = false) {
      this.stopAndSaveAsInterrupted(overDeck)
      await this.createNewActiveTimeEntry()
    },
    async stopAndSaveAsInterrupted(overDeck = false) {
      let oldId = await this.stopActiveEntry(null, false, overDeck)
      if (oldId) {
        this.interruptedId = oldId
      }
    },
    async stopAndGoBackToInterruptedEntry(overDeck = false) {
      // stopActiveEntry will remove the interruptedId. With toRaw the proxy/ref will be removed and the primitiv value will be used.
      let interruptId = toRaw(this.interruptedId)
      await this.stopActiveEntry(null, false, overDeck)
      if (interruptId) {
        let previousEntry = this.getId(interruptId)
        if (!previousEntry) {
          return
        }
        await this.createNewActiveTimeEntry(interruptId)
      }
      else {
        await this.createNewActiveTimeEntry()
      }
    },

    /*
     * delete functions
     */

    async deleteId(id, isRetry = false) {
      const entry = this.getId(id)
      if (!entry) {
        return
      }
      this.resetIds()

      if (id == 'tempNewEntry') {
        this.tempNewEntry = null
        return
      }

      if (id.startsWith('temp-')) {
        useApiStore().removeIdEverywhere('timeEntries', id)
        this.deletedIds.push(id)

        this.removeId(id)
        this.renewConflicts()

        return
      }
      timelinkStoresService.removeTimeout(this.$id, entry.id)
      timelinkStoresService.removeInterval(this.$id, entry.id)
      timelinkStoresService.removeWatcher(this.$id, entry.id)
      try {
        let response = await apiService.deleteEntry(
          'timeEntries',
          import.meta.env.VITE_API_URL + '/api/v1/timeEntries',
          id,
          null,
          isRetry
        )
        let data = response.data ?? null

        if (response.success != undefined && response.success) {
          if (data.id == id) {
            this.removeId(id)
            this.deletedIds.push(id)
          }
          this.removeFromConflicts(id)
          this.renewConflicts()
        } else {
          throw new Error('Could not delete the time entry')
        }
      } catch (error) {
        if (error.code == 'ERR_NETWORK' || error.code == 'ERR_CANCELED') {
          entry.tl.error = 'Verbindung zum Server nicht möglich! Du bist möglicherweise offline!'
          entry.tl.error_code = 1
        } else {
          console.error(error)
          captureException(error)
          entry.tl.error =
            'Es ist ein Fehler beim Löschen aufgetreten! Versuche es erneut oder kontaktiere unseren Support!'
          entry.tl.error_code = 9999
          if (import.meta.env.DEV) {
            entry.tl.dev_error = error.message
          }
        }
      }
    },

    /*
     * internal functions
     */
    removeId(id) {
      this.timeEntries = this.timeEntries.filter((item) => item.id != id)

      if (this.activeTimeEntry && this.activeTimeEntry == id) {
        this.$patch({
          activeTimeEntry: null,
          lastActiveTimeEntryChangeOrFetch: Date.now()
        })
      }
    },
    removeSearchFlags(entry) {
      if (entry.client_id) {
        timelinkStoresService.removeSearchFlag(useClientsStore().getId(entry.client_id))
      }
      if (entry.service_id) {
        timelinkStoresService.removeSearchFlag(useServicesStore().getId(entry.service_id))
      }
      if (entry.project_id) {
        timelinkStoresService.removeSearchFlag(useProjectsStore().getId(entry.project_id))
      }
    },
    resetIds() {
      this.$patch({
        newIds: [],
        changedIds: [],
        createdIds: [],
        deletedIds: []
      })
    },
    resetEntry(id) {
      let o_entry = null
      this.resetIds()
      if (id == 'tempNewEntry') {
        this.tempNewEntry = null
        return
      }
      if (useApiStore().hasId('timeEntries', id)) {
        return
      }
      if (id.startsWith('temp-')) {
        return
      }
      if ((o_entry = this.timeEntries.find((item) => id === item.id))) {
        timelinkStoresService.updateTl(o_entry)
        if (o_entry.tl.isDirty) {
          Object.entries(o_entry).forEach((item) => {
            if (item[0] == 'tl') {
              return
            }
            o_entry[item[0]] = o_entry.tl.origin[item[0]]
          })
          timelinkStoresService.updateTl(o_entry)
        }
        this.changedIds.push(o_entry.id)
      }
    },
    clearSearch() {
      let client_ids = []
      let project_ids = []
      let service_ids = []

      this.timeEntries.forEach((entry) => {
        if (entry.client_id) {
          client_ids.push(entry.client_id)
        }
        if (entry.project_id) {
          project_ids.push(entry.project_id)
        }
        if (entry.service_id) {
          service_ids.push(entry.service_id)
        }
      })

      if (client_ids.length > 0) {
        useClientsStore().removeSearch(client_ids)
      }
      if (service_ids.length > 0) {
        useServicesStore().removeSearch(service_ids)
      }
      if (project_ids.length > 0) {
        useProjectsStore().removeSearch(project_ids)
      }
    },
    clearOld() {
      let entries = this.getInterval(this.actualTimeInterval.start, this.actualTimeInterval.end)
      let sec_entries = this.getInterval(
        this.selectedTimeInterval.start,
        this.selectedTimeInterval.end
      )
      let conflict_ids = []
      this.conflicts.forEach((item) => {
        conflict_ids.push(this.getId(item.a))
        conflict_ids.push(this.getId(item.b))
      })

      let client_ids = []
      let project_ids = []
      let service_ids = []
      let timeEntry_ids = []

      if (this.activeTimeEntry) {
        timeEntry_ids.push(this.activeTimeEntry)
      }

      entries.forEach((item) => {
        if (item.client_id) {
          client_ids = client_ids.filter((sItem) => sItem == item.client_id)
          client_ids.push(item.client_id)
        }
        if (item.project_id) {
          project_ids = project_ids.filter((sItem) => sItem == item.project_id)
          project_ids.push(item.project_id)
        }
        if (item.service_id) {
          service_ids = service_ids.filter((sItem) => sItem == item.service_id)
          service_ids.push(item.service_id)
        }
        if (!timelinkStoresService.isTempId(item.id)) {
          timeEntry_ids.push(item.id)
        }
      })
      sec_entries.forEach((item) => {
        if (item.client_id) {
          client_ids = client_ids.filter((sItem) => sItem == item.client_id)
          client_ids.push(item.client_id)
        }
        if (item.project_id) {
          project_ids = project_ids.filter((sItem) => sItem == item.project_id)
          project_ids.push(item.project_id)
        }
        if (item.service_id) {
          service_ids = service_ids.filter((sItem) => sItem == item.service_id)
          service_ids.push(item.service_id)
        }
        if (!timelinkStoresService.isTempId(item.id)) {
          timeEntry_ids.push(item.id)
        }
      })
      conflict_ids.forEach((item) => {
        if (item.client_id) {
          client_ids = client_ids.filter((sItem) => sItem == item.client_id)
          client_ids.push(item.client_id)
        }
        if (item.project_id) {
          project_ids = project_ids.filter((sItem) => sItem == item.project_id)
          project_ids.push(item.project_id)
        }
        if (item.service_id) {
          service_ids = service_ids.filter((sItem) => sItem == item.service_id)
          service_ids.push(item.service_id)
        }
        if (!timelinkStoresService.isTempId(item.id)) {
          timeEntry_ids.push(item.id)
        }
      })
      useClientsStore().removeOld(client_ids)
      useProjectsStore().removeOld(project_ids)
      useServicesStore().removeOld(service_ids)
      this.timeEntries = this.timeEntries.filter(
        (item) => timelinkStoresService.isTempId(item.id) || timeEntry_ids.includes(item.id)
      )
    },
    loadRelations(entry) {
      if (entry.client_id) {
        useClientsStore().fetchIfNotExists(entry.client_id)
      }
      if (entry.project_id) {
        useProjectsStore().fetchIfNotExists(entry.project_id)
      }
      if (entry.service_id) {
        useServicesStore().fetchIfNotExists(entry.service_id)
      }
    },

    getColor(timeEntry) {
      const project_color = useProjectsStore().getId(timeEntry.project_id)?.color
      const client_color = useClientsStore().getId(timeEntry.client_id)?.color
      const service_color = useServicesStore().getId(timeEntry.service_id)?.color
      return project_color ?? client_color ?? service_color ?? '#333333'
    },

    async fetchConflicts() {
      try {
        const response = await apiService.fetch(
          import.meta.env.VITE_API_URL + '/api/v1/timeEntries/conflicts'
        )
        let data = response?.data ?? []
        let ids = []
        if (!Array.isArray(data)) {
          captureMessage('Conflict send other object than array.', [typeof data])
          return
        }
        data.forEach((item) => {
          ids = ids.filter((sItem) => item['a'] != sItem && item['b'] != sItem)
          ids.push(item['a'])
          ids.push(item['b'])
          this.addConflict(item['a'], item['b'], item['type'])
        })
        this.fetchIds(ids)
      } catch (error) {
        captureException(error)
      }
    },

    searchConflicts() {
      this.conflicts = this.conflicts.filter((item) => {
        let a = this.getId(item.a)
        let b = this.getId(item.b)
        if (a == null || b == null) {
          return false
        }
        let start_a = Date.parse(a.started_at)
        let start_b = Date.parse(b.started_at)
        let end_a = Date.parse(a.ended_at)
        let end_b = Date.parse(b.ended_at)

        return (
          (start_a > start_b && end_a < end_b) ||
          (start_a < start_b && end_b < end_a) ||
          (start_a < start_b && start_b < end_a) ||
          (start_a < end_b && end_b < end_a)
        )
      })
      this.timeEntries.forEach((a, index_a) => {
        if (a.id == this.activeTimeEntry) {
          return
        }
        this.timeEntries.forEach((b, index_b) => {
          if (index_b <= index_a) {
            return
          }
          if (b.id == this.activeTimeEntry || a.id == b.id) {
            return
          }
          let start_a = Date.parse(a.started_at)
          let start_b = Date.parse(b.started_at)
          let end_a = Date.parse(a.ended_at)
          let end_b = Date.parse(b.ended_at)

          if ((start_a > start_b && end_a < end_b) || (start_a < start_b && end_b < end_a)) {
            this.addConflict(a.id, b.id, 2)
          } else if (start_a < start_b && start_b < end_a) {
            this.addConflict(a.id, b.id, 1)
          } else if (start_a < end_b && end_b < end_a) {
            this.addConflict(b.id, a.id, 1)
          }
        })
        if (a.push_state != null && a.push_state != 0) {
          this.addConflict(a.id, null, 9)
        }
      })
    },

    /**
     *
     * @param {string} a - ID
     * @param {?string} b - ID
     * @param {number} type - 1 and 2 are for time overlapping entries and 9 is for sync errors
     */
    addConflict(a, b = null, type) {
      this.resolvedConflict(a, b)
      this.conflicts.push({ a: a, b: b, type: type })
    },

    renewConflicts() {
      timelinkStoresService.setOrRenewTimeout(
        this.$id,
        'searchConflicts',
        () => {
          this.searchConflicts()
        },
        1000
      )
    },

    /**
     *
     * @param {string} a - ID
     * @param {?string} b - ID
     */
    resolvedConflict(a, b = null) {
      this.conflicts = this.conflicts.filter((item) => {
        return !((item.a == a && item.b == b) || (item.a == b && item.b == a))
      })
    },

    removeFromConflicts(id) {
      this.conflicts = this.conflicts.filter((item) => item.a != id && item.b != id)
    },

    // it will check if the automatic_stop is set and it will check if the recoding is over the set time
    async automaticStop() {
      let automatic_stop = useAuthUserStore().user?.settings?.automaticStopOfRecording ?? null
      timelinkStoresService.removeTimeout(this.$id, 'automaticStop')
      timelinkStoresService.removeTimeout(this.$id, 'handleAutomaticStop')
      if (!automatic_stop) {
        return
      }
      if (!this.activeTimeEntry) {
        timelinkStoresService.setOrRenewTimeout(
          this.$id,
          'automaticStop',
          this.automaticStop,
          60_000
        )
        return
      }

      if (
        this.getActiveTimeEntry.ended_at != undefined &&
        this.getActiveTimeEntry.ended_at != null
      ) {
        timelinkStoresService.setOrRenewTimeout(
          this.$id,
          'automaticStop',
          this.automaticStop,
          60_000
        )
        this.internalUpdateActiveTimeEntry()
        return
      }

      timelinkStoresService.setOrRenewTimeout(
        this.$id,
        'handleAutomaticStop',
        this.handleAutomaticStop,
        200
      )
    },
    handleAutomaticStop() {
      let automatic_stop = useAuthUserStore().user?.settings?.automaticStopOfRecording ?? null
      timelinkStoresService.removeTimeout(this.$id, 'automaticStop')
      timelinkStoresService.removeTimeout(this.$id, 'handleAutomaticStop')
      if (!automatic_stop) {
        return
      }
      if (!this.activeTimeEntry) {
        timelinkStoresService.setOrRenewTimeout(
          this.$id,
          'automaticStop',
          this.automaticStop,
          60_000
        )
        return
      }

      if (
        this.getActiveTimeEntry.ended_at != undefined &&
        this.getActiveTimeEntry.ended_at != null
      ) {
        timelinkStoresService.setOrRenewTimeout(
          this.$id,
          'automaticStop',
          this.automaticStop,
          60_000
        )
        this.internalUpdateActiveTimeEntry()
        return
      }
      if (
        this.getActiveTimeEntry.ended_at == null &&
        this.getActiveTimeEntry?.tl?.origin?.updated_at &&
        this.getActiveTimeEntry?.tl?.origin?.ended_at &&
        Date.parse(this.getActiveTimeEntry.tl.origin.updated_at) >
        Date.parse(this.getActiveTimeEntry.updated_at)
      ) {
        this.getActiveTimeEntry.tl.isDirty = false
        this.addOrUpdate({ ...this.getActiveTimeEntry.tl.origin })
        return
      }
      let startActive = Date.parse(this.getActiveTimeEntry.started_at)
      let startDay = new Date(startActive)
      let [hour, minute] = automatic_stop.split(':')

      if (Number(hour) == 0 && Number(minute) == 0) {
        hour = 24
      }
      let stopAtStartDay = new Date(
        startDay.getFullYear(),
        startDay.getMonth(),
        startDay.getDate(),
        hour,
        minute,
        0,
        0
      )

      if (stopAtStartDay.valueOf() > startActive) {
        if (stopAtStartDay.valueOf() < Date.now()) {
          if (this.stopActiveEntry(stopAtStartDay.toISOString(), true) !== false) {
            useNotificationsStore().send({
              type: 'automaticStop',
              data: { time: automatic_stop },
              until: Date.now() + 24 * 60 * 60 * 1000
            })
          }
          return
        } else {
          let timeoutTimer = Math.min(Math.max(100, stopAtStartDay.valueOf() - Date.now()), 60_000)
          timelinkStoresService.setOrRenewTimeout(
            this.$id,
            'automaticStop',
            () => {
              this.automaticStop
            },
            timeoutTimer
          )
          return
        }
      } else {
        stopAtStartDay = new Date(
          stopAtStartDay.getFullYear(),
          stopAtStartDay.getMonth(),
          stopAtStartDay.getDate(),
          hour + 24,
          minute,
          0,
          0
        )

        if (stopAtStartDay.valueOf() < Date.now()) {
          if (this.stopActiveEntry(stopAtStartDay.toISOString(), true) !== false) {
            useNotificationsStore().send({
              type: 'automaticStop',
              data: { time: automatic_stop },
              until: Date.now() + 24 * 60 * 60 * 1000
            })

          }
          return
        } else {
          let timeoutTimer = Math.min(Math.max(100, stopAtStartDay.valueOf() - Date.now()), 60_000)
          timelinkStoresService.setOrRenewTimeout(
            this.$id,
            'automaticStop',
            () => {
              this.automaticStop
            },
            timeoutTimer
          )
          return
        }
      }
    },
    notifications() {
      // this.remindActiveTracking()
      // this.doBreak()
      // this.toStartTracking()
      timelinkStoresService.setOrRenewTimeout(
        this.$id,
        'notifications',
        () => {
          this.remindActiveTracking()
          this.doBreak()
          this.toStartTracking()
          this.notifications()
        },
        60_000
      )
    },
    remindActiveTracking() {
      if (idleService.screenState() == 'locked') {
        return
      }
      const remindActiveTracking =
        useAuthUserStore().user?.settings?.notifications?.remindActiveTracking ?? null
      if (remindActiveTracking?.active ?? true) {
        if (this.activeTimeEntry) {
          let diff = Math.round(
            (Date.now() - Date.parse(this.getActiveTimeEntry.started_at)) / 1000 / 60
          )
          if (diff == (remindActiveTracking?.after ?? 60)) {
            useNotificationsStore().send({
              type: 'remindActiveTracking',
              options: { store: false },
              until: Date.now() + 30 * 60 * 1000
            })
          }
        }
      }
    },
    doBreak() {
      if (idleService.screenState() == 'locked') {
        return
      }
      const doBreak = useAuthUserStore().user?.settings?.notifications?.doBreak ?? null
      if (!(doBreak?.active ?? true)) {
        return
      }
      let breakAt = doBreak?.time ?? '12:00'
      const [hour, minute] = breakAt.split(':')
      let now = new Date(Date.now())
      let breakDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minute)
      let diff = Math.round((Date.now() - Date.parse(breakDate)) / 1000)

      if (0 <= diff && diff < 60) {
        useNotificationsStore().send({
          type: 'doBreak',
          options: { store: false },
          until: Date.now() + 2 * 60 * 60 * 1000
        })
      }
    },
    toStartTracking() {
      if (idleService.screenState() == 'locked') {
        return
      }


      if (this.activeTimeEntry) {
        return
      }


      const toStartTracking =
        useAuthUserStore().user?.settings?.notifications?.toStartTracking ?? null
      if (!(toStartTracking?.active ?? true)) {
        return
      }


      const actualDay = new Date(Date.now()).getDay()
      if (!(toStartTracking?.weekDays ?? [1, 2, 3, 4, 5]).includes(actualDay)) {
        return
      }

      // Check if we are at the defined time range for reminders
      let [startHour, startMinute] = (toStartTracking?.between?.start ?? '08:00').split(':')
      let [endHour, endMinute] = (toStartTracking?.between?.end ?? '17:00').split(':')
      let now = datetime.date(Date.now())
      let start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMinute)
      let end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endHour, endMinute)
      // For ranges that are going over one day
      if (Date.parse(end) < Date.parse(start)) {
        end = end + 1000 * 60 * 60 * 24
      }
      if (Date.parse(start) >= Date.now() || Date.now() >= Date.parse(end)) {
        return
      }



      let repeatAfter = (toStartTracking?.repeatAfter ?? 30)
      let nextNotificationPossible = this.lastNotificationToStartTracking
        ? this.lastNotificationToStartTracking + (repeatAfter ?? 30) * 1000 * 60
        : 0

      console.log(this.lastNotificationToStartTracking, nextNotificationPossible, this.activeTimeEntry)

      // only uses repeat after
      if (nextNotificationPossible > Date.now()) {
        return
      }
      else {
        if (repeatAfter == 0) {
          return
        }
      }

      // first check if there is still a timeentry behind the after value. 
      // it will also catch updates from websockets so that a user don't get notified if he created an entry 
      // on an other browser
      let after = toStartTracking?.after ?? 2
      let repeatAfterNotify = (toStartTracking?.repeatAfter ?? 30) == 0 ? 30 : toStartTracking?.repeatAfter ?? 30
      let latest = this.getInterval(
        datetime.iso(Date.now() - (toStartTracking?.after ?? 2) * 1000 * 60),
        datetime.iso(Date.now())
      )
      console.log(latest)

      if (latest.length > 0) {
        return
      }
      useNotificationsStore().send(
        {
          type: 'toStartTracking',
          options: { store: false },
          until: Date.now() + (after * 1000 * 60) + (repeatAfterNotify * 2 * 60 * 1000)
        }
      )
      console.log(this.lastNotificationToStartTracking)
      this.lastNotificationToStartTracking = Date.now()
      console.log(this.lastNotificationToStartTracking)

    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTimeEntryStore, import.meta.hot))
}
