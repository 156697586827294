<!-- TODO: Translation -->
<template>
  <div class="flex flex-col items-center justify-center flex-grow w-full min-h-screen">
    <LoadingSpinner v-model="showSpinner" :fullPage="true"></LoadingSpinner>
    <div class="w-full max-w-xl space-y-8 bg-white border rounded-2xl border-mossgray-200">
      <div class="w-full p-12 pt-0 space-y-6">
        <div>
          <div class="flex justify-center px-16 py-8 mt-6 -mb-2">
            <img :src="img" />
          </div>

          <h2 class="text-lg text-center">
            <span v-if="inLoginProcess">Du wirst gerade angemeldet.<br />Bitte habe einen Moment Geduld.</span>
          </h2>
        </div>
        <form class="space-y-4" action="#" method="POST" v-if="!inLoginProcess">
          <input type="hidden" name="remember" value="true" />
          <div class="-space-y-px rounded-md shadow-sm">
            <div>
              <label for="email-address" class="sr-only">Email address</label>
              <input v-model="user.username" id="email-address" name="email" type="email" autocomplete="email" required
                class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border rounded-none appearance-none border-mossgray-200 rounded-t-md focus:outline-none focus:ring-mossgray focus:border-mossgray focus:z-10 sm:text-sm"
                placeholder="E-Mail Adresse" />
            </div>
            <div>
              <label for="password" class="sr-only">Password</label>
              <input v-model="user.password" id="password" name="password" type="password"
                autocomplete="current-password" required
                class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border rounded-none appearance-none border-mossgray-200 rounded-b-md focus:outline-none focus:ring-mossgray focus:border-mossgray focus:z-10 sm:text-sm"
                placeholder="Passwort" />
            </div>
          </div>
          <!-- TODO: Translation -->
          <div class="text-sm text-center">
            <div class="text-red-500" v-text="message"></div>
            <div class="pb-2 my-2" v-show="showRedirect">Du wirst automatisch weitergeleitet.</div>
          </div>

          <div>
            <button type="submit" @click.prevent="handleLogin"
              class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md group bg-mossgray hover:bg-white hover:text-mossgray ring-2 ring-mossgray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mossgray">
              <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <!-- TOOD: FontAwesome Icon -->
                <!-- Heroicon name: solid/lock-closed -->
                <svg class="w-5 h-5 text-white group-hover:text-mossgray" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clip-rule="evenodd" />
                </svg>
              </span>
              Anmelden
            </button>
          </div>
          <div class="px-2 text-center">
            <router-link :to="{ name: 'ResetPassword' }" class="text-sm font-medium hover:underline">
              Passwort vergessen?
            </router-link>
            <div class="mt-2 text-base leading-4 text-center" v-if="!inLoginProcess">
              <div class="mt-4 text-sm">
                Du hast noch keinen Account?<br />
                <router-link to="/registration" class="font-medium hover:underline">
                  Jetzt anmelden und 14 Tage kostenlos ausprobieren
                </router-link>
              </div>
            </div>
          </div>
        </form>
        <div class="" v-if="!inLoginProcess">
          <div class="mb-2 text-sm font-semibold">Weitere Anmeldeoptionen:</div>
          <!-- TODO: Refine hover effect -->
          <div class="flex flex-col mb-2 border divide-y rounded-md divide-mossgray-200 border-mossgray-200">
            <a v-bind:href="microsoft_oauth" class="flex items-center p-3 space-x-3 rounded-t-md hover:underline">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                <title>MS-SymbolLockup</title>
                <rect x="1" y="1" width="9" height="9" fill="#f25022" />
                <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
                <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
                <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
              </svg>
              <!-- <span class="text-sm font-semibold">Sign in with an work or school account</span> -->
              <span class="text-sm font-semibold">Anmeldung über ein Arbeits- oder Schulkonto</span>
            </a>
            <router-link to="/sso" class="flex items-center p-3 space-x-4 rounded-b-md hover:underline">
              <font-awesome-icon :icon="['fa-kit', 'tl-key']" />
              <span class="text-sm font-semibold">
                SSO
                <span v-if="ssoAddress">( <span class="text-mossgray" v-text="ssoAddress"></span> )</span></span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthUserStore } from '@/stores/auth-user'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import deckService from '@/services/deck.service'
import DesktopService from '@/services/desktop.service'
import { captureException } from '@sentry/vue'
import img from '@/assets/timelink_primary_logo_5f_moosgrauRGB.svg'

export default {
  name: 'LoginUser',
  components: {
    LoadingSpinner
  },
  setup() {
    const authUserStore = useAuthUserStore()
    const desktopService = DesktopService

    // if (authUserStore.isAuthenticated) {
    //   this.$router.push('/')
    // }
    return { authUserStore, desktop: desktopService, img }
  },
  data() {
    let api_domain = import.meta.env.VITE_API_URL
    return {
      // loading: false,
      message: '',
      messageSso: '',
      is_dev: import.meta.env.DEV,
      user: {
        username: '',
        password: ''
      },
      domain: null,
      set_callback: false,
      microsoft_oauth: api_domain + '/oauth/microsoft/',
      redirect_url: '',
      showSpinner: false,
      showRedirect: false,
      ssoAddress: null,
      inLoginProcess: false
    }
  },

  mounted() {
    if (this.authUserStore.salt == null) {
      this.authUserStore.salt = Math.random().toString(20).substr(2, 6)
    }
    this.loadStreamDeck()
    if (this.desktop.getConfig('ssoDomain')) {
      this.ssoAddress = this.desktop.getConfig('ssoDomain')
    }
  },
  created() {
    if (this.$route.query['error_code']) {
      const error_code = this.$route.query['error_code'] ?? null
      if (error_code == 10) {
        this.message =
          'Dein Account existiert nicht oder ist nicht aktiv. Bitte wende dich an an deinen Administrator.'
      } else if (error_code == 20) {
        this.message =
          'Das Lizenzmodell deines Unternehmens lässt keine weiteren Registrierungen zu.'
      } else if (error_code == 50) {
        this.message =
          'Diese Anmeldemethode wird durch dein Unternehmen nicht unterstützt. Bitte melde dich über SSO an.'
      } else if (error_code == 70) {
        this.message = 'Du hast die Anmeldung über einen anderen Anbieter abgebrochen.'
      } else if (error_code == 71) {
        this.message = 'Deine Anmelde-Sitzung war abgelaufen. Bitte versuche es erneut.'
      } else if (error_code != null) {
        this.message = 'Beim Anmelden ist ein Fehler aufgetreten. Bitte versuche es erneut.'
      }
    }
    if (this.authUserStore.salt == null) {
      this.authUserStore.salt = Math.random().toString(20).substr(2, 6)
    }
    const givenTokenSalt = this.$route.params['token'] ?? null
    let givenToken = givenTokenSalt ?? null
    if (givenTokenSalt != null) {
      let givenTokenRaw
      try {
        givenTokenRaw = atob(givenTokenSalt)
      } catch (error) {
        givenTokenRaw = givenTokenSalt
      }
      let givenSalt = givenTokenRaw.substring(0, this.authUserStore.salt.length)
      if (givenSalt != this.authUserStore.salt) {
        givenToken = givenTokenRaw
      } else {
        givenToken = givenTokenRaw.substring(this.authUserStore.salt.length)
      }
    }
    if (!this.set_callback) {
      this.redirect_url = window.location.toString()
      // if (givenToken != null) {
      // }
      this.redirect_url = this.redirect_url.substring(
        0,
        this.redirect_url.indexOf('/login') + '/login'.length
      )
      this.microsoft_oauth =
        this.microsoft_oauth +
        this.authUserStore.salt +
        '?redirect=' +
        encodeURIComponent(this.redirect_url)
    }
    if (givenToken) {
      this.inLoginProcess = true
    }
    setTimeout(() => {
      this.authUserStore
        .checkLoginStatus(givenToken)
        .then((response) => {
          this.showSpinner = false
          this.inLoginProcess = false
          if (response == true) {
            this.$router.push('/')
          } else if (response === false) {
            if (givenToken) {
              this.$router.push('/login' + (givenToken ? '?error_code=99' : ''))
              this.message = 'Beim Anmelden ist ein Fehler aufgetreten. Bitte versuche es erneut.'
            }
          } else if (response === null) {
            this.$router.push('/login' + (givenToken ? '?error_code=98' : ''))
            if (givenToken) {
              this.message = 'Beim Anmelden ist ein Fehler aufgetreten. Bitte versuche es erneut.'
            }
          }
        })
        .catch((error) => {
          this.$router.push('/login' + (givenToken ? '?error_code=99' : ''))
          if (givenToken) {
            this.message = 'Beim Anmelden ist ein Fehler aufgetreten. Bitte versuche es erneut.'
          }
          this.inLoginProcess = false
          captureException(error)
        })
    }, 50)

    if (this.loggedIn) {
      this.$router.push('/profile')
    }
  },
  computed: {
    loggedIn() {
      return this.authUserStore.isAuthenticated
    }
  },
  methods: {
    async handleLogin() {
      // this.loading = true
      try {
        await this.authUserStore.loggedIn(this.user)
        this.$router.push('/')
      } catch (error) {
        // this.loading = false
        if (error.response) {
          if (error.response.status == 401 || error.response.status == 422) {
            this.message = this.$t('login.errors.invalid_credentials')
          }
          if (error.response.status == 403) {
            this.message = this.$t('login.errors.user_not_active')
          }
          if (error.response.status == 400) {
            if (error.response.data.error_code == 10) {
              if (error.response.data?.url) {
                this.handleSso(error.response.data.url)
              }
              if (error.response.data.provider == 'microsoft') {
                this.message = this.$t('login.errors.forced_oauth.microsoft')
              } else {
                this.message = this.$t('login.errors.forced_oauth.other')
              }
            }
          }
        } else if (error.code === 'ERR_NETWORK') {
          this.message = this.$t('login.errors.no_connection')
        } else {
          this.message = this.$t('errors.ups')
        }
      }
      // this.authUserStore.login(this.user).then(
      //   () => {
      //     this.$router.push('/')
      //   },
      //   (error) => {
      //     this.loading = false
      //     this.message =
      //       error.response && (error.response.status == 401 || error.response.status == 422)
      //         ? 'Ihre Anmeldedaten sind falsch'
      //         : error?.response?.status == 403
      //           ? 'Ihr Benutzeraccount ist nicht aktiv. Bitte kontaktieren Sie Ihren Administrator'
      //           : 'Es ist ein Fehler aufgetreten'
      //   }
      // )
    },
    async handleSso(url) {
      this.showRedirect = true
      this.redirect_url = window.location.toString()
      this.redirect_url = this.redirect_url.substring(0, this.redirect_url.indexOf('/login'))
      this.redirect_url = this.redirect_url + '/login'
      let redirect_to = url
      redirect_to =
        redirect_to +
        '/' +
        this.authUserStore.salt +
        '?redirect=' +
        encodeURIComponent(this.redirect_url) +
        (this.user.username != '' ? '&email=' + this.user.username : '')

      await new Promise((r) => setTimeout(r, 3000))
      window.location.href = redirect_to
    },
    async loadStreamDeck() {
      await deckService.init()
      await deckService.showLoginScreen()
    }
  }
}
</script>
